import React from "react";

import { Key } from "./Key";
import { KeyboardKey } from "../data/KeyboardLayout";
import { areLettersCompatible } from "../utils/AlphabetUtils";
import { TUseKeyboardLayout } from "../hooks/useKeyboardLayout";

import s from "./Keyboard.module.scss";

export type TProps = {
	markWrong: readonly string[];
	expectedLetter: string;
	layout: Readonly<TUseKeyboardLayout>;
	onPressKey?: (key: KeyboardKey) => void;
};

export const Keyboard = ({ markWrong, expectedLetter, layout, onPressKey }: TProps): JSX.Element => {
	return (
		<div className={s.main}>
			{layout.rows.map((keyRow, i) => (
				<div key={`key-row-${i}`} className={s.row}>
					{keyRow.map((keyInfo, j) => {
						const isIncompatible =
							keyInfo.isIncompatible ||
							(!layout.disabled && Boolean(keyInfo.letter && !areLettersCompatible(expectedLetter, keyInfo.letter)));
						const isWrong = Boolean(keyInfo.letter && markWrong.includes(keyInfo.letter));
						return (
							<Key
								key={`key-${j}`}
								label={keyInfo.label ?? "⚠"}
								onPress={() => onPressKey?.(keyInfo.key)}
								disabled={keyInfo.isDisabled}
								incompatible={isIncompatible}
								isWrong={isWrong && !layout.disabled}
								isLarge={keyInfo.isLarge}
							/>
						);
					})}
				</div>
			))}
		</div>
	);
};
