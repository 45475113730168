import React, { ChangeEvent, useCallback } from "react";

import { KeyboardLayoutId, KeyboardLayouts } from "../data/KeyboardLayout";
import useSettings, { LightMode } from "../hooks/useSettings";

import s from "./TextSettings.module.scss";

export type TProps = {
	className?: string;
};

export const TextSettings = ({ className }: TProps): JSX.Element => {
	const settings = useSettings();

	const handleChangeLightMode = useCallback(
		(e: ChangeEvent<HTMLSelectElement>) => {
			const newValue = e.target.value as unknown as LightMode;
			settings.setLightMode(newValue);
		},
		[settings.setLightMode],
	);
	const handleChangeHighContrast = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const newValue = e.target.checked;
			settings.setHighContrast(newValue);
		},
		[settings.setHighContrast],
	);
	const handleChangeKeyboardLayout = useCallback(
		(e: ChangeEvent<HTMLSelectElement>) => {
			const newValue = e.target.value as unknown as KeyboardLayoutId;
			settings.setKeyboardLayout(newValue);
		},
		[settings.setKeyboardLayout],
	);

	return (
		<div className={className}>
			<div className={s.row}>
				<div className={s.columnLeft}>Light Mode</div>
				<div className={s.columnRight}>
					<select onChange={handleChangeLightMode} value={settings.lightMode}>
						<option value={LightMode.Auto}>Auto</option>
						<option value={LightMode.AlwaysDark}>Always Dark</option>
						<option value={LightMode.AlwaysLight}>Always Light</option>
					</select>
				</div>
			</div>
			<div className={s.row}>
				<div className={s.columnLeft}>High Contrast</div>
				<div className={s.columnRight}>
					<input type={"checkbox"} checked={settings.highContrast} onChange={handleChangeHighContrast} />
				</div>
			</div>
			<div className={s.row}>
				<div className={s.columnLeft}>Keyboard Type</div>
				<div className={s.columnRight}>
					<select value={settings.keyboardLayout} onChange={handleChangeKeyboardLayout}>
						{Object.entries(KeyboardLayouts).map(([k, v]) => (
							<option id={k} key={k}>
								{v.name}
							</option>
						))}
					</select>
				</div>
			</div>
		</div>
	);
};
