import { useCallback, useMemo } from "react";

import { usePersistentState } from "./usePersistentState";
import { KeyboardLayoutId } from "./../data/KeyboardLayout";

export enum LightMode {
	AlwaysLight = "always-light",
	AlwaysDark = "always-dark",
	Auto = "auto",
}

export type TSettings = {
	lightMode: LightMode;
	highContrast: boolean;
	keyboardLayout: KeyboardLayoutId;
};

export type TUseSettings = TSettings & {
	setLightMode: (value: TSettings["lightMode"]) => void;
	setHighContrast: (value: TSettings["highContrast"]) => void;
	setKeyboardLayout: (value: TSettings["keyboardLayout"]) => void;
};

const DEFAULT_STATE: TSettings = {
	lightMode: LightMode.Auto,
	highContrast: false,
	keyboardLayout: KeyboardLayoutId.QWERTY,
};

/**
 * All the app's settings
 */
const useSettings = (): TUseSettings => {
	const [settings, setSettings] = usePersistentState<TSettings>(`game-settings`, DEFAULT_STATE);

	const setLightMode = useCallback(
		(value: LightMode): void => {
			setSettings((oldSettings) => {
				return {
					...oldSettings,
					lightMode: value,
				};
			});
		},
		[setSettings],
	);

	const setHighContrast = useCallback(
		(value: boolean): void => {
			setSettings((oldSettings) => {
				return {
					...oldSettings,
					highContrast: value,
				};
			});
		},
		[setSettings],
	);

	const setKeyboardLayout = useCallback(
		(value: KeyboardLayoutId): void => {
			setSettings((oldSettings) => {
				return {
					...oldSettings,
					keyboardLayout: value,
				};
			});
		},
		[setSettings],
	);

	return useMemo<TUseSettings>(
		() => ({
			...DEFAULT_STATE,
			...settings,
			setLightMode,
			setHighContrast,
			setKeyboardLayout,
		}),
		[settings, setLightMode, setHighContrast, setKeyboardLayout],
	);
};

export default useSettings;
