export type TKeyboardLayout = {
	name: string;
	rows: KeyboardKey[][]; // https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
};

export enum KeyboardKey {
	// Should be the same as Key.code
	Empty = "Empty",
	Backspace = "Backspace",
	ArrowLeft = "ArrowLeft",
	ArrowRight = "ArrowRight",
	ArrowUp = "ArrowUp",
	ArrowDown = "ArrowDown",
	A = "a",
	B = "b",
	C = "c",
	D = "d",
	E = "e",
	F = "f",
	G = "g",
	H = "h",
	I = "i",
	J = "j",
	K = "k",
	L = "l",
	M = "m",
	N = "n",
	O = "o",
	P = "p",
	Q = "q",
	R = "r",
	S = "s",
	T = "t",
	U = "u",
	V = "v",
	W = "w",
	X = "x",
	Y = "y",
	Z = "z",
}

const SPECIAL_KEYS: KeyboardKey[] = [
	KeyboardKey.Empty,
	KeyboardKey.Backspace,
	KeyboardKey.ArrowLeft,
	KeyboardKey.ArrowRight,
	KeyboardKey.ArrowUp,
	KeyboardKey.ArrowDown,
];

const SPECIAL_KEYS_DISABLED: KeyboardKey[] = [KeyboardKey.Empty];

const SPECIAL_KEY_LABELS: Record<string, string> = {
	[KeyboardKey.Backspace]: "⌫",
};

export const isKeyAlwaysDisabled = (key: KeyboardKey): boolean => {
	return SPECIAL_KEYS_DISABLED.includes(key);
};

export const keyToLabelTry = (key: KeyboardKey): string | null => {
	const label = keyToLetterTry?.(key)?.toUpperCase() ?? SPECIAL_KEY_LABELS[key] ?? null;
	if (!label) {
		return null;
	}
	return label;
};

export const keyToLetterTry = (key: KeyboardKey): string | null => {
	if (SPECIAL_KEYS.includes(key)) {
		return null;
	}

	return key.toLowerCase();
};

export const keyToLetter = (key: KeyboardKey): string => {
	const letter = keyToLetterTry(key);
	if (!letter) {
		throw new Error(`Invalid key: "${key}" doesn't have a letter representation.`);
	}

	return key.toLowerCase();
};

export const keyCodeToKeyTry = (keyCode: string): KeyboardKey | null => {
	if (Object.keys(KeyboardKey).includes(keyCode)) {
		// Special keys like "Backspace" are the same
		return keyCode as KeyboardKey;
	} else if (keyCode.startsWith("Key")) {
		// E.g. "KeyA" -> "a"
		return keyCode.substring(3).toLowerCase() as KeyboardKey;
	} else {
		return null;
	}
};

export const uniqueKeys = (keys: KeyboardKey[]): KeyboardKey[] => {
	return keys.filter((l, i, self) => self.indexOf(l) == i);
};

export enum KeyboardLayoutId {
	QWERTY = "QWERTY",
	AZERTY = "AZERTY",
	Colemak = "Colemak",
	Dvorak = "Dvorak",
}

export const KeyboardLayouts: Record<KeyboardLayoutId, TKeyboardLayout> = {
	[KeyboardLayoutId.QWERTY]: {
		name: "QWERTY",
		rows: [
			[
				KeyboardKey.Q,
				KeyboardKey.W,
				KeyboardKey.E,
				KeyboardKey.R,
				KeyboardKey.T,
				KeyboardKey.Y,
				KeyboardKey.U,
				KeyboardKey.I,
				KeyboardKey.O,
				KeyboardKey.P,
			],
			[
				KeyboardKey.A,
				KeyboardKey.S,
				KeyboardKey.D,
				KeyboardKey.F,
				KeyboardKey.G,
				KeyboardKey.H,
				KeyboardKey.J,
				KeyboardKey.K,
				KeyboardKey.L,
			],
			[
				KeyboardKey.Z,
				KeyboardKey.X,
				KeyboardKey.C,
				KeyboardKey.V,
				KeyboardKey.B,
				KeyboardKey.N,
				KeyboardKey.M,
				KeyboardKey.Backspace,
			],
		],
	},
	[KeyboardLayoutId.AZERTY]: {
		name: "AZERTY",
		rows: [
			[
				KeyboardKey.A,
				KeyboardKey.Z,
				KeyboardKey.E,
				KeyboardKey.R,
				KeyboardKey.T,
				KeyboardKey.Y,
				KeyboardKey.U,
				KeyboardKey.I,
				KeyboardKey.O,
				KeyboardKey.P,
				KeyboardKey.Empty,
			],
			[
				KeyboardKey.Q,
				KeyboardKey.S,
				KeyboardKey.D,
				KeyboardKey.F,
				KeyboardKey.G,
				KeyboardKey.H,
				KeyboardKey.J,
				KeyboardKey.K,
				KeyboardKey.L,
			],
			[
				KeyboardKey.W,
				KeyboardKey.X,
				KeyboardKey.C,
				KeyboardKey.V,
				KeyboardKey.B,
				KeyboardKey.N,
				KeyboardKey.Empty,
				KeyboardKey.Backspace,
			],
		],
	},
	[KeyboardLayoutId.Colemak]: {
		name: "Colemak",
		rows: [
			[
				KeyboardKey.Q,
				KeyboardKey.W,
				KeyboardKey.F,
				KeyboardKey.P,
				KeyboardKey.G,
				KeyboardKey.J,
				KeyboardKey.L,
				KeyboardKey.U,
				KeyboardKey.Y,
				KeyboardKey.Empty,
				KeyboardKey.Empty,
			],
			[
				KeyboardKey.A,
				KeyboardKey.R,
				KeyboardKey.S,
				KeyboardKey.T,
				KeyboardKey.D,
				KeyboardKey.H,
				KeyboardKey.N,
				KeyboardKey.E,
				KeyboardKey.I,
				KeyboardKey.O,
			],
			[
				KeyboardKey.Z,
				KeyboardKey.X,
				KeyboardKey.C,
				KeyboardKey.V,
				KeyboardKey.B,
				KeyboardKey.K,
				KeyboardKey.M,
				KeyboardKey.Backspace,
			],
		],
	},
	[KeyboardLayoutId.Dvorak]: {
		name: "Dvorak",
		rows: [
			[
				KeyboardKey.Empty,
				KeyboardKey.Empty,
				KeyboardKey.Empty,
				KeyboardKey.P,
				KeyboardKey.Y,
				KeyboardKey.F,
				KeyboardKey.G,
				KeyboardKey.C,
				KeyboardKey.R,
				KeyboardKey.L,
				KeyboardKey.Backspace,
			],
			[
				KeyboardKey.A,
				KeyboardKey.O,
				KeyboardKey.E,
				KeyboardKey.U,
				KeyboardKey.I,
				KeyboardKey.D,
				KeyboardKey.H,
				KeyboardKey.T,
				KeyboardKey.N,
				KeyboardKey.S,
				KeyboardKey.Empty,
			],
			[
				KeyboardKey.Empty,
				KeyboardKey.Q,
				KeyboardKey.J,
				KeyboardKey.K,
				KeyboardKey.X,
				KeyboardKey.B,
				KeyboardKey.M,
				KeyboardKey.W,
				KeyboardKey.V,
				KeyboardKey.Z,
			],
		],
	},
};

export const getKeyboardLayoutFromId = (id: KeyboardLayoutId): TKeyboardLayout => {
	return KeyboardLayouts[id] ?? KeyboardLayouts.QWERTY;
};
