import { SetStateAction, useCallback, useEffect, useMemo, useState } from "react";

type TKeyType = string | number;
type TSetValueFunc<T> = (valueOrFunc: SetStateAction<T>) => void;
type TDefaultValueOrGetFunc<T> = T | (() => T);
type TJSON = string | number | boolean | null | TJSON[] | { [key: string]: TJSON };
export type TUsePersistentState<T extends TJSON> = [T, TSetValueFunc<T>];

const parseOrUseDefault = <T>(value: string | null, defaultValue: T): T => {
	return value ? JSON.parse(value) : defaultValue;
};

type TLocalStorageEvent = Pick<StorageEvent, "key" | "newValue">;
const LOCAL_STORAGE_EVENT = "__storage_event_changed_local__";

/**
 * Temporary data cleaner for older versions.
 * We'll need to remove this later.
 */
export const cleanupPersistentData = (prefix: string, count: number): void => {
	while (count >= 0) {
		localStorage.removeItem(prefix + count);
		count--;
	}
};

export const usePersistentState = <T extends TJSON>(key: string, defaultValue: T): TUsePersistentState<T> => {
	const [memoryValue, setMemoryValue] = useState<string | null>(() => localStorage.getItem(key));

	const parsedValue = useMemo(() => {
		return parseOrUseDefault(memoryValue, defaultValue);
	}, [defaultValue, memoryValue]);

	useEffect(() => {
		const handleStorageChange = (e: TLocalStorageEvent): void => {
			if (e.key === key) {
				setMemoryValue(e.newValue);
			}
		};

		// Since "storage" events only work for changes done in different windows,
		// we create a new event just to track those changes ourselves

		addEventListener("storage", handleStorageChange);
		// @ts-ignore
		addEventListener(LOCAL_STORAGE_EVENT, handleStorageChange, false);

		return () => {
			removeEventListener("storage", handleStorageChange);
			// @ts-ignore
			removeEventListener(LOCAL_STORAGE_EVENT, handleStorageChange, false);
		};
	}, [key]);

	const setValue = useCallback(
		(v: SetStateAction<T>) => {
			setMemoryValue((prevValue) => {
				const newValueObject = typeof v === "function" ? v(parseOrUseDefault(prevValue, defaultValue)) : v;
				const newValue = JSON.stringify(newValueObject);
				if (localStorage.getItem(key) !== newValue) {
					localStorage.setItem(key, newValue);
					requestAnimationFrame(() => {
						const localStorageEvent = new Event(LOCAL_STORAGE_EVENT);
						// @ts-ignore
						localStorageEvent.key = key;
						// @ts-ignore
						localStorageEvent.newValue = newValue;
						dispatchEvent(localStorageEvent);
					});
				}
				return newValue;
			});
		},
		[key],
	);

	return [parsedValue, setValue];
};

const getDefaultValue = <T>(v: TDefaultValueOrGetFunc<T>): T => {
	if (typeof v === "function") {
		// TODO: not sure why the cast is needed here
		return (v as () => T)();
	} else {
		return v;
	}
};

export const usePersistentIndexedState = <T extends TJSON>(
	storageKey: string,
	indexKey: TKeyType,
	defaultValue: TDefaultValueOrGetFunc<T>,
): TUsePersistentState<T> => {
	const [savedState, setSavedState] = usePersistentState<Record<TKeyType, T>>(storageKey, {});

	const actualDefaultValue = useMemo(() => {
		const staticDefaultValue = getDefaultValue(defaultValue);
		return Array.isArray(staticDefaultValue)
			? ((staticDefaultValue as TJSON[]).concat() as T)
			: typeof staticDefaultValue === "object"
				? { ...staticDefaultValue }
				: staticDefaultValue;
	}, [defaultValue]);

	const thisState = useMemo((): T => {
		return savedState[indexKey] ?? actualDefaultValue;
	}, [savedState, indexKey]);

	const setThisState = useCallback(
		(v: SetStateAction<T>) => {
			setSavedState((prevSavedState) => {
				const newThisState = typeof v === "function" ? v(prevSavedState[indexKey] ?? actualDefaultValue) : v;
				return {
					...prevSavedState,
					[indexKey]: newThisState,
				};
			});
		},
		[indexKey, setSavedState, actualDefaultValue],
	);

	return [thisState, setThisState];
};

export default usePersistentState;
