import { useMemo } from "react";
import {
	KeyboardKey,
	TKeyboardLayout,
	isKeyAlwaysDisabled,
	keyToLabelTry,
	keyToLetterTry,
} from "../data/KeyboardLayout";

type TKeyInfo = {
	key: KeyboardKey;
	letter: string | null;
	label: string | null;
	isDisabled: boolean;
	isLarge: boolean;
	isIncompatible: boolean;
};

type TKeyInfoMap = Record<KeyboardKey, TKeyInfo>;

export type TUseKeyboardLayout = {
	rows: TKeyInfo[][];
	keys: TKeyInfoMap;
	disabled: boolean;
};

const ALL_KEYS = Object.values(KeyboardKey);

/**
 * Return the current layout and other state information of the keyboard
 */
const useKeyboardLayout = (
	disabled: boolean,
	lettersDisabled: boolean,
	disabledBackspace: boolean,
	usedLetters: readonly string[],
	keyboardLayout: TKeyboardLayout,
): Readonly<TUseKeyboardLayout> => {
	return useMemo<TUseKeyboardLayout>(() => {
		const keyList = ALL_KEYS.map((key): TKeyInfo => {
			const letter = keyToLetterTry(key);
			const label = keyToLabelTry(key);
			const isKeyIncompatible = isKeyAlwaysDisabled(key);
			const isKeyDisabled =
				disabled ||
				isKeyIncompatible ||
				(lettersDisabled && Boolean(letter)) ||
				Boolean((letter && usedLetters.includes(letter)) || (disabledBackspace && key === KeyboardKey.Backspace));
			const isKeyLarge = !letter && !isKeyIncompatible;
			return {
				key,
				letter,
				label,
				isDisabled: isKeyDisabled,
				isLarge: isKeyLarge,
				isIncompatible: isKeyIncompatible,
			};
		});
		const keys = Object.fromEntries(keyList.map((keyInfo) => [keyInfo.key, keyInfo])) as TKeyInfoMap;

		return {
			disabled,
			keys,
			rows: keyboardLayout.rows.map((row): TKeyInfo[] => row.map((key) => keys[key])),
		};
	}, [keyboardLayout, disabled, disabledBackspace, usedLetters]);
};

export default useKeyboardLayout;
